* {
    margin: 0;
    padding: 0;
    font-family: 'telefonicaWeb';
    box-sizing: border-box;
}
@font-face {
    font-family: telefonicaWeb;
    src: url(assets/fonts/telefonica-web.ttf);
}
@font-face {
    font-family: telefonicaBold;
    src: url(assets/fonts/telefonica-bold.ttf);
}
:root {
    color-scheme: only light;
  }